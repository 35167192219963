import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const Science = () => (
  <Layout>
    <SEO title="The Science" />
    <div class="banner">
      <div class="container">
        <div class="colx1">
          <div class="copy">
            <h3 class="center">
              Discover the research supporting cytisine
            </h3>
            <a class="btn_alt" href="#"><i class="fa fa-angle-down"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="colx2">
          <div class="card">
            <h6 class="subline">
              Walker, Natalie, et al
            </h6>
            <h5 class="left">
              Cytisine versus nicotine for smoking cessation. (2014)
            </h5>
            <a class="btn_alt" href="http://www.nejm.org/doi/full/10.1056/NEJMoa1407764#t=article" target="_blank" rel="noopener noreferrer">Read More <i className="fa fa-angle-right"></i></a>
          </div>
        </div>
        <div class="colx2">
          <div class="card">
            <h6 class="subline">
              Castaldelli-Maia, Joao Mauricio
            </h6>
            <h5 class="left">
              Cytisine versus Nicotine Replacement Treatment Real-Life Effectiveness for Smoking Cessation. (2016)
            </h5>
            <a class="btn_alt" href="http://www.jto.org/article/S1556-0864(16)33288-9/fulltext" target="_blank" rel="noopener noreferrer">Read More <i className="fa fa-angle-right"></i></a>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="colx2">
          <div class="card">
            <h6 class="subline">
              Walker, Natalie, et al
            </h6>
            <h5 class="left">
              Getting cytisine licensed for use world‐wide: a call to action. (2016)
            </h5>
            <a class="btn_alt" href="http://onlinelibrary.wiley.com/doi/10.1111/add.13464/abstract" target="_blank" rel="noopener noreferrer">Read More <i className="fa fa-angle-right"></i></a>
          </div>        
        </div>
        <div class="colx2">
          <div class="card">
            <h6 class="subline">
              Zatoński, Witold, and Mateusz Zatoński
            </h6>
            <h5 class="left">
              Cytisine versus nicotine for smoking cessation. (2015)
            </h5>
            <a class="btn_alt" href="http://www.nejm.org/doi/full/10.1056/NEJMc1500342#t=article" target="_blank" rel="noopener noreferrer">Read More <i className="fa fa-angle-right"></i></a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Science
